html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

html, body, #root {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  /* font-family: 'Barlow', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

.portal-hide-scrollbars {
  -ms-overflow-style: none;
}

.portal-hide-scrollbars::-webkit-scrollbar {
  width: 0;
}

.portal-flex {
  flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  background: rgba(0,0,0,0.050);
}

.portal-pages__header-demo {
  max-width: 400px;
  margin-top: 0;
  margin-bottom: 150px;
  padding: 0px 48px;
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
}

.portal-pages__content-inner {
  margin-top: -200px;
  padding: 16px;
  box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
          animation: portal-pages__content-inner .3s forwards 1 ease-in-out;
}

@-webkit-keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes portal-pages__content-inner {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

 .logo {
     position: absolute;
     top: 20px;
     left: 20px;
     width: 400px;
     height: 32px;
     padding: 0px;
     text-align: left;
 }

 .logo a {
     display: block;
     position: relative;
     color: #fff;
     text-decoration: none;
     font-weight: 700;
     font-size: 20px;
     line-height: 32px;
 }

 .logo a .logo-image {
    max-height: 32px;
    max-width: 32px;
    float: left;
    margin-right: 10px;
 }
.sidenav_portal-toolbar-brand__1pkCu {
  max-height: 64px;
  max-width: 100%;
  padding: 8px 0px;
  box-sizing: border-box;
  object-fit: contain; }

.sidenav_portal-toolbar-brand-title__82-Ef {
  font-weight: 600 !important; }

.sidenav_portal-toolbar-brand-text__1RFRW {
  overflow: hidden;
  text-overflow: ellipsis; }

.sidenav_portal-sidenav-paymachine__3K_5X, .sidenav_portal-sidenav-classic__2ItYz, .sidenav_portal-sidenav-toolbar__2CBWy, .sidenav_portal-sidenav-boxed__3wFSK {
  width: 286px; }

.sidenav_portal-sidenav-compact__2NgRX {
  width: 120px; }

.sidenav_portal-sidenav-funky__hVis_ {
  width: 96px; }

.layout-paymachine_layout-paymachine-wrapper__1dwMR {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row; }

.layout-paymachine_layout-paymachine-main__2w2Pb {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.layout-paymachine_layout-paymachine-content-wrapper__3Qtmv {
  flex: 1 1 100%;
  box-sizing: border-box;
  flex-direction: column-reverse;
  display: flex;
  position: relative;
  overflow: hidden; }

.layout-paymachine_layout-paymachine-content__2vsN4 {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto; }

.login_panel__1tKSh {
  -webkit-animation: login_portal-login__2rp_n 1s forwards 1;
          animation: login_portal-login__2rp_n 1s forwards 1;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0; }

.login_card__3n5U6 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px; }
  @media screen and (max-width: 568px) {
    .login_card__3n5U6 {
      padding: 12px; } }

.login_login-actions__qVrJG {
  justify-content: space-between; }

.login_login-actions__qVrJG a {
  width: 50%;
  text-transform: none; }

@media screen and (max-width: 568px) {
  .login_cardContent__3C1Ad {
    padding: 12px !important; } }

.login_signup-content__14rJh {
  text-align: left;
  font-size: 14px; }
  @media screen and (max-width: 568px) {
    .login_signup-content__14rJh {
      padding: 12px !important; } }

.login_signup-content-paragraph__17Ivp {
  color: #fff !important;
  margin-bottom: 20px !important;
  line-height: 24px !important; }
  @media screen and (max-width: 568px) {
    .login_signup-content-paragraph__17Ivp {
      margin-bottom: 0 !important;
      font-size: 0.9rem !important;
      line-height: 0.9rem !important; } }

.login_signup-logo__1xxxY {
  height: 25px;
  margin-right: 10px;
  float: left; }
  @media screen and (max-width: 568px) {
    .login_signup-logo__1xxxY {
      height: 20px; } }

.login_signup-logo-text__3QsdR {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff !important;
  margin-bottom: 15px !important; }
  @media screen and (max-width: 568px) {
    .login_signup-logo-text__3QsdR {
      font-size: 16px !important;
      margin-bottom: 8px !important; } }

@-webkit-keyframes login_portal-login__2rp_n {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1; } }

@keyframes login_portal-login__2rp_n {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1; } }

