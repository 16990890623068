


.panel {
  animation: portal-login 1s forwards 1;
  animation-delay: 0.6s;
  transform: translateY(20px);
  opacity: 0;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  @media screen and (max-width: 568px){
    padding: 12px;
  }
}

.login-actions {
  justify-content: space-between;
}

.login-actions a {
  width: 50%;
  text-transform: none;
}

.cardContent {
   @media screen and (max-width: 568px){
    padding: 12px !important;
  }
}

.signup-content {
  text-align: left;
  font-size: 14px;
  @media screen and (max-width: 568px){
    padding: 12px !important;
  }
}

.signup-content-paragraph {
  color: #fff !important;
  margin-bottom: 20px !important;
  line-height: 24px !important;
  @media screen and (max-width: 568px){
    margin-bottom: 0 !important;
    font-size: 0.9rem !important;
    line-height: 0.9rem !important;
  }
}

.signup-logo {
  height: 25px;
  margin-right: 10px;
  float: left;
  @media screen and (max-width: 568px){
    height: 20px;
  }
}

.signup-logo-text {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #fff !important;
  margin-bottom: 15px !important;
  @media screen and (max-width: 568px){
    font-size: 16px !important;
    margin-bottom: 8px !important;
  }
}

@keyframes portal-login {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
