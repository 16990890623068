.layout-paymachine-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
}

.layout-paymachine-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.layout-paymachine-content-wrapper {
  flex: 1 1 100%;
  box-sizing: border-box;
  flex-direction: column-reverse;
  display: flex;
  position: relative;
  overflow: hidden;
}

.layout-paymachine-content {
  height: 100%;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  overflow-y: auto;
}
