.portal-toolbar-brand {
  max-height: 64px;
  max-width: 100%;
  padding: 8px 0px;
  box-sizing: border-box;
  object-fit: contain;
}
.portal-toolbar-brand-title {
  font-weight: 600 !important;
}
.portal-toolbar-brand-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.portal-sidenav-paymachine, .portal-sidenav-classic, .portal-sidenav-toolbar, .portal-sidenav-boxed {
  // width: 256px;
  width: 286px;
}
.portal-sidenav-compact {
  width: 120px;
}

.portal-sidenav-funky {
  width: 96px;
}
